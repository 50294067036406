import { WhatsApp } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "..";
import Logo from "../assets/drawable/hb-logo.png";

const NavBar = ({ whatsApp }) => {
  const [lang, setLang] = useGlobalState("lang");
  
  return (
    <Stack padding="20px" justifyContent="space-between" direction="row">
      <Box>
        <img className="logo" src={Logo} alt="" />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          minWidth: { xs: "30%", md: "10%" },
          gap: "15px"
        }}
      >
      {localStorage.getItem('display') === 'true' ? <div className="qr-code">
          <img src="/assets/qr-code.png " style={{width: "10vh"}} alt="qr-code"/>
          <h6 style={{fontSize: "8px"}}>Visualiser sur mobile</h6>
      </div>: null}
        
      {localStorage.getItem('display') === "false" || !localStorage.getItem('display') ? (
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            href="https://wa.me/212650074238"
          >
            <Box
              sx={{
                color: "green",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                fontSize: "25px",
                fontWeight: "600",
              }}
            >
              <WhatsApp fontSize="35px" color="green" />
            </Box>
          </a>
        ) : null}
        <img
          style={{ cursor: "pointer" }}
          className="langImg"
          src="/assets/fr.png"
          onClick={() => {
            setLang("fr");
            localStorage.setItem("language", "fr");
          }}
        />
        <img
          style={{ cursor: "pointer" }}
          className="langImg"
          src="/assets/us.png"
          onClick={() => {
            setLang("en");
            localStorage.setItem("language", "en");
          }}
        />
      </Box>
    </Stack>
  );
};

export default NavBar;

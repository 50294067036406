import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../..";
import "./category.css";

const Category = (props) => {
  const navigate = useNavigate();
  const [lang] = useGlobalState("lang");
  const category = props.values;

  return (
    <div
      className="category-item"
      onClick={() => {
        navigate(`/services/${category._id}`);
      }}
    >
      <div className="category-img-box">
        <img
          className="category-img"
          src={category.image}
          alt={category.title[lang]}
          loading="lazy"
        />
      </div>
      <div className="category-title-box">
        <a
          className="categoryLink"
          onClick={() => navigate(`/services/${category._id}`)}
        >
          {category.title[lang]}
        </a>
      </div>
    </div>
  );
};

export default Category;

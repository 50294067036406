import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Category from "../../components/category/Category";
import NavBar from "../../components/NavBar";
import Popup from "../../components/Popup";
import AppServices from "../../services/AppServices";
import "./Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useGlobalState("loader");
  const [lang, setLang] = useGlobalState("lang");
  const [show, setShow] = useState(true);
  const [popupData, setPopupData] = useState(false);
  const [video, setVideo] = useState({
    video: "",
    title: "",
  });

  const handleYoutube = (video) => {
    if (video.includes("watch")) {
      return `https://www.youtube.com/embed/${
        video.split("v=", 2)[1].split("&")[0]
      }?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${
        video.split("v=", 2)[1].split("&")[0]
      }&controls=0`;
    } else {
      return `https://www.youtube.com/embed/${
        video.split("be/", 2)[1]
      }?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${
        video.split("be/", 2)[1]
      }&controls=1`;
    }
  };

  // useEffect(() => {
  //   console.log(popupData);
  // }, [popupData]);

  useEffect(() => {
    setLoader(true);
    AppServices.post(
      {
        action: 3,
      },
      "/get"
    ).then((response) => {
      setCategories(response.data);
      setLoader(false);
    });

    AppServices.post(
      {
        action: 7,
        data: {
          display: localStorage.getItem("display") === "true" ? true : false,
        },
      },
      "/get"
    ).then((response) => {
      if (response.data) {
        setPopupData(response.data[0]);
      }
    });

    AppServices.post(
      {
        action: 0,
        data: {
          user_action: 0,
          from_client:
            localStorage.getItem("display") === "false" ||
            !localStorage.getItem("display")
              ? true
              : false,
          token: "-1",
        },
      },
      "/track"
    );
    AppServices.post(
      {
        action: 10,
      },
      "/get"
    ).then((response) => {
      setVideo(response.data[0]);
    });
  }, []);

  const nm = categories.length;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <NavBar />
      {popupData ? <Popup data={popupData} /> : null}
      <div className="videotheque">
        {/* <h2>Vidéothèque</h2> */}
        {video.video.includes("youtu") ? (
          <iframe
            width="80%"
            height="450"
            src={handleYoutube(video.video)}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : video.video ? (
          <img src={video.video} height={550} width="80%" />
        ) : null}
        <div className="left-text">
          <h4>{video.title}</h4>
        </div>
      </div>
      <div
        className={nm > 3 ? "categories-container" : "grid-category-containerr"}
      >
        {categories.map((category, i) => {
          return <Category values={category} num={nm} key={i} />;
        })}
      </div>
      {/* <Grid className="grid">
        
      </Grid> */}
    </Box>
  );
};

export default Categories;

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from "./pages/categories/Categories";
import Services from "./pages/services/Services";
import ServicePage from "./pages/service-page/ServicePage";
import Confirmation from "./pages/confirmation/Confirmation";
import ConfirmSuccess from "./pages/confirm-success/ConfirmSuccess";
import Reservation from "./pages/reservation/Reservation";
import { createGlobalState } from "react-hooks-global-state";
import Loader from "./components/Loader";
import CustomDatPicker from "./components/CustomDatePicker";
import "bootstrap/dist/css/bootstrap.min.css";
import Iframe from "./components/Iframe";
import Display from "./components/Display";
import ReservationProcess from "./pages/ReservationProcess";
import Client from "./components/Client";
import PopupPDF from "./components/PopupPDF";

const root = ReactDOM.createRoot(document.getElementById("root"));

const { setGlobalState, useGlobalState } = createGlobalState({
  lang:
    localStorage.getItem("language") !== (null && "" && undefined)
      ? localStorage.getItem("language")
      : "fr",
  loader: false,
  reservation: {
    reservation_date: "",
    number_of_people: "",
    room_number: "",
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
  },
  step: 0,
  url: "",
});

const App = () => {
  const [lang] = useGlobalState("lang");

  // useEffect(() => {
  //   console.log(lang);
  // }, [lang]);

  const [loading] = useGlobalState("loader");
  return (
    <BrowserRouter>
      {loading ? <Loader /> : null}
      <Routes>
        <Route path="/" element={<Categories />}></Route>
        <Route path="/services/:category_id" element={<Services />}></Route>
        <Route path="/services/details/:service_id" element={<ServicePage />}></Route>
        <Route path="/reservation/:id" element={<ReservationProcess />}></Route>
        <Route path="/iframe" element={<Iframe />}></Route>
        <Route path="/confirm" element={<Confirmation />}></Route>
        <Route path="/confirm-success" element={<ConfirmSuccess />}></Route>
        <Route path="/display" element={<Display />}></Route>
        <Route path="/client" element={<Client />}></Route>
        <Route path="/pdf" element={<PopupPDF />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

root.render(<App />);

export { setGlobalState, useGlobalState };

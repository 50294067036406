import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import fr from "date-fns/locale/fr";
import { format } from 'date-fns';

const CustomDatPicker = ({ setData, data }) => {
  const [selected, setSelected] = useState();

  const handleDaySelect = (date) => {
    setSelected(date);
    setData({
      ...data,
      date: format(date, 'dd-MM-y')
    })
  };
  return (
    <DayPicker
      fromYear={new Date().getFullYear()}
      toYear={2033}
      captionLayout="dropdown"
      required
      locale={fr}
      mode="single"
      selected={selected}
      onSelect={handleDaySelect}
    />
  );
};

export default CustomDatPicker;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import textversionjs from "textversionjs";
import { useGlobalState } from "..";


const Popup =  (props) => {
    const [lang, setLang] = useGlobalState("lang");
    const [show, setShow] = useState(true)
    const handleClose = () => setShow(false);
    const data = props.data

    useEffect(() => {
      if (document.getElementById('popup-text') !== (null && undefined)){
        document.getElementById('popup-text').innerHTML = data ? data.text[lang] : ""
      } 
    }, [lang, data])
    

    return (
        <>
        {data ? 
        <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img alt="" style={{ width: "40%" }} src="/assets/hb-logo.png" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }} >
          <div id="popup-text"></div>
        </Modal.Body>
      </Modal> : null}</>
    )
}

export default Popup
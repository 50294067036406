import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../..";
import ArrowBack from "../../assets/drawable/left-arrow.svg";
import Service from "../../components/service/Service";
import AppServices from "../../services/AppServices";
import NavBar from "../../components/NavBar";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const Services = () => {
  const { category_id } = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [loader, setLoader] = useGlobalState("loader");
  const [lang] = useGlobalState("lang");

  useEffect(() => {
    setLoader(true);
    AppServices.post(
      {
        action: 5,
        data: {
          category: category_id,
        },
      },
      "/get"
    ).then((response) => {
      setServices(response.data);
      setLoader(false);
    });
  }, [category_id, setLoader]);
  // console.log(services);
  return (
    <Box style={{ marginBottom: "3rem" }}>
      <NavBar />
      <Box
        marginLeft={{ md: "20px", xs: "20px" }}
        margin="20px 0px"
        display="flex"
        alignItems="center"
      >
        <button className="goBackBut" onClick={() => navigate("/")}>
          <img className="goBackArr" src={ArrowBack} alt="" />
        </button>
        <Typography
          fontWeight={400}
          fontFamily="Freight Big Bold Italic"
          variant="h4"
        >
          {/* {services.length > 0
            ? services[0].category.title[lang]
            : "Loading..."} */}
          {services.length == 0 ? (
            lang === "en" ? (
              <p className="noService">No Services are Available</p>
            ) : (
              <p className="noService">Aucun service n'est disponible</p>
            )
          ) : services.length > 0 ? (
            services[0].category.title[lang]
          ) : (
            "Loading..."
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          display: { xs: "flex", md: "grid" },
          flexDirection: { xs: "column", md: "initial" },
          gap: "3rem",
          gridTemplateColumns: "repeat(3, 1fr)",
          padding: { md: "20px", xs: "0px" },
          marginLeft: { xs: "20px", md: "0" },
        }}
      >
        {services.map((service, i) => {
          return <Service service={service} key={i} />
        })}
      </Box>
      {localStorage.getItem("display") === "true" ?
        (function () {
          const idleDurationSecs = 30; // X number of seconds
          const redirectUrl = "/"; // Redirect idle users to this URL
          let idleTimeout; // variable to hold the timeout, do not modify

          const resetIdleTimeout = function () {
            // Clears the existing timeout
            if (idleTimeout) clearTimeout(idleTimeout);

            // Set a new idle timeout to load the redirectUrl after idleDurationSecs
            idleTimeout = setTimeout(
              () => (window.location.href = redirectUrl),
              idleDurationSecs * 1000
            );
          };

          // Init on page load
          resetIdleTimeout();

          // Reset the idle timeout on any of the events listed below
          ["click", "touchstart", "mousemove"].forEach((evt) =>
            document.addEventListener(evt, resetIdleTimeout, false)
          );
        })() : null}
    </Box>
  );
};

export default Services;

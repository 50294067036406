import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigation, useParams } from "react-router-dom";
import { useGlobalState } from "..";
import NavBar from "./NavBar";
import ArrowBack from "../assets/drawable/left-arrow.svg"

const Iframe = () => {
  const [url, setURL] = useGlobalState("url");
  const [, setLoading] = useGlobalState("loader");

  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleIframeLoad);
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleIframeLoad);
      }
    };
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [url]);

  return (
    <Box className="reservationBox">
      <NavBar />
      <button
        className="goBackBut float"
        onClick={() => (window.location = "/")}
      >
        <img src={ArrowBack} alt="" />
      </button>
      <Box
        height="88vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <iframe
          style={{ width: "70vw", minHeight: "40rem" }}
          src={url}
          ref={iframeRef}
          frameBorder="0"
          onLoad={handleIframeLoad}
        ></iframe>
      </Box>
    </Box>
  );
};

export default Iframe;

import React, { useState } from "react";
import CustomDatPicker from "../../components/CustomDatePicker";
import "./Reservation.css";
import ArrowBack from "../../assets/drawable/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import Line from "./Line 13.png";
import NavBar from "../../components/NavBar";
import { useGlobalState } from "../..";

const Reservation = ({ setData, data }) => {
  const [lang] = useGlobalState("lang");
  const navigate = useNavigate()
  const [step, setStep] = useGlobalState("step")
  const [content, setContent] = useState({
    title: {
      en: "Reservation request",
      fr: "Demande de réservation"
    },
    date: {
      en: "Select date",
      fr: "Sélectionner une date"
    },
    people: {
      en: "Number of persons",
      fr: "Nombre de personnes"
    },
    reservation: {
      en: "Go to next step",
      fr: "Aller à l'étape suivante"
    }
  })

  return (
    <>
      <NavBar />
      <Box
        display="flex"
        flexDirection="column"
        sx={{ padding: { xs: "20px", md: "20px 100px" } }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <button
            style={{ margin: "0" }}
            className="goBackBut"
            onClick={() => navigate(`/`)}
          >
            <img className="logo" src={ArrowBack} alt="" />
          </button>
          <div className="reservation-header">
            <h1 className="home-title">{content.title[lang]}</h1>

            <img className="line" src={Line} alt="" />
          </div>
          <div style={{ width: "40px" }}></div>
        </Stack>
        <h6 className="subHeader">{localStorage.getItem("service_name")}</h6>
        <h6 className="dateSelectHead">{content.date[lang]}</h6>
        <Box
          justifyContent="center"
          display="flex"
          sx={{
            width: { xs: "100%", md: "100%" },
            textTransform: "capitalize",
          }}
        >
          <CustomDatPicker setData={setData} data={data}/>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "30%" },
            alignSelf: "center",
          }}
        >
          <label className="__form-label">{content.people[lang]}</label>
          <div className="personsCount">
            <button
              onClick={() => {
                if(data.people_count > 1){
                  setData({
                      ...data,
                      people_count: data.people_count - 1
                  })
                }
              }}
              className="button plus"
            >
              -
            </button>
            <input
              className="personsInput"
              type="number"
              min="1"
              value={data.people_count}
              id="person-count"
            />
            <button
              onClick={() => setData({
                  ...data,
                  people_count: data.people_count + 1
              })}
              className="button plus"
            >
              +
            </button>
          </div>

          <a className="button reserve" id="reserve" onClick={() => {
            setStep(step + 1)
          }}>
            {content.reservation[lang]}
          </a>
        </Box>
      </Box>
    </>
  );
};

export default Reservation;

import React, { useState } from "react";
import Logo from "../../assets/drawable/hb-logo.png";
import CheckIcon from "../../assets/drawable/check.svg";
import { Box } from "@mui/material";
import { useGlobalState } from "../..";


export default function ConfirmSuccess() {
  const [lang] = useGlobalState("lang");

  const [content, setContent] = useState({
    title: {
      en: "Reservation request sent",
      fr: "Demande de réservation envoyée"
    },
    description: {
      en: "A member of our staff will contact you as soon as possible to confirm your reservation. A summary email has been sent to you.",
      fr: "Un membre de notre staff vous contactera dans les plus brefs délais pour confirmer votre réservation. Un email récapitulatif vous a été envoyé."
    },
    confirm: {
      en: "Back to the home page",
      fr: "Retour à l’acceuil"
    }
  })

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10vh"
        }}
      >
        <Box
          sx={{
            padding: "0px 20px",
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            className="confirmation-page"
          >
            <img style={{ margin: "20px 0px" }} src={Logo} alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "space-evenly",
              height: "40vh",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              margin: "50px 0px",
            }}
          >
            <h1>{content.title[lang]}</h1>
            <img src={CheckIcon} alt="" />
            <p>
              {content.description[lang]}
            </p>
          </Box>
          <a className="button reserve" href="/">
            {content.confirm[lang]}
          </a>
          {localStorage.getItem("display") === "true" &&
            (function () {
              const idleDurationSecs = 30; // X number of seconds
              const redirectUrl = "/"; // Redirect idle users to this URL
              let idleTimeout; // variable to hold the timeout, do not modify

              const resetIdleTimeout = function () {
                // Clears the existing timeout
                if (idleTimeout) clearTimeout(idleTimeout);

                // Set a new idle timeout to load the redirectUrl after idleDurationSecs
                idleTimeout = setTimeout(
                  () => (window.location.href = redirectUrl),
                  idleDurationSecs * 1000
                );
              };

              // Init on page load
              resetIdleTimeout();

              // Reset the idle timeout on any of the events listed below
              ["click", "touchstart", "mousemove"].forEach((evt) =>
                document.addEventListener(evt, resetIdleTimeout, false)
              );
            })()}
        </Box>
      </Box>
    </>
  );
}

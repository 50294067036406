import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../..";
import ArrowBack from "../../assets/drawable/left-arrow.svg";
import NavBar from "../../components/NavBar";
import Line from "../reservation/Line 13.png";

export default function Confirmation({ setData, data }) {
  const navigate = useNavigate();
  const [step, setStep] = useGlobalState("step")
  const [lang] = useGlobalState("lang");


  const [content, setContent] = useState({
    title: {
      en: "Booking information",
      fr: "Informations de réservation"
    },
    room_number: {
      en: "Room number",
      fr: "Numéro de la chambre"
    },
    last_name: {
      en: "Last name",
      fr: "Nom de famille"
    },
    first_name: {
      en: "First name",
      fr: "Prénom"
    },
    confirm: {
      en: "Send booking request",
      fr: "Envoyer la demande de réservation"
    }

  })

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleNext = () => {
    if (data.room_number !== "" && data.ext !== "" && data.phone !== "" && data.last_name !== "" && data.first_name !== "" && data.email !== ""){
      setStep(step + 1)
    }else{
      alert("Veuillez vérifier les champs obligatoires. Merci!")
    }
  }


  return (
    <>
      <NavBar />
      <Box sx={{ padding: "0px 20px" }} id="confirm-page">
        <script src="./assets/js/data/CountriesPhoneCodes.js"></script>
        <Stack
          margin="20px 0px"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <button
            style={{ margin: "0" }}
            className="goBackBut"
            onClick={() => setStep(step - 1)}
          >
            <img className="logo" src={ArrowBack} alt="" />
          </button>
          <div className="reservation-header">
            <h1 className="home-title">{content.title[lang]}</h1>

            <img className="line lineC" src={Line} alt="" />
          </div>
          <div style={{ width: "40px" }}></div>
        </Stack>
        <form className="form" action="./confirmation-success">
          <Stack sx={{ marginBottom: "20px" }} spacing={3}>
            <input
              name="room_number"
              className="inputRoom input"
              placeholder={content.room_number[lang]}
              required
              onChange={handleChange}
            />
            <div className="input-grou">
              <input
                name="ext"
                className="input inputNum"
                placeholder="+212"
                value="+212"
                required
                onChange={handleChange}

              />
              <input
                name="phone"
                className="input numb"
                placeholder="6 61 20 20 20"
                required
                onChange={handleChange}

              />
            </div>
            <input
              name="last_name"
              className="inputRoom input"
              placeholder={content.last_name[lang]}
              required
              onChange={handleChange}

            />
            <input
              name="first_name"
              className="inputRoom input"
              placeholder={content.first_name[lang]}
              required
              onChange={handleChange}

            />
            <input
              name="email"
              className="inputRoom input"
              placeholder="Email"
              required
              onChange={handleChange}

            />
          </Stack>
          <button
            className="button reserve"
            onClick={handleNext}
          >
            {content.confirm[lang]}
          </button>
        </form>
        {localStorage.getItem("display") === "true" &&
          (function () {
            const idleDurationSecs = 30; // X number of seconds
            const redirectUrl = "/"; // Redirect idle users to this URL
            let idleTimeout; // variable to hold the timeout, do not modify

            const resetIdleTimeout = function () {
              // Clears the existing timeout
              if (idleTimeout) clearTimeout(idleTimeout);

              // Set a new idle timeout to load the redirectUrl after idleDurationSecs
              idleTimeout = setTimeout(
                () => (window.location.href = redirectUrl),
                idleDurationSecs * 1000
              );
            };

            // Init on page load
            resetIdleTimeout();

            // Reset the idle timeout on any of the events listed below
            ["click", "touchstart", "mousemove"].forEach((evt) =>
              document.addEventListener(evt, resetIdleTimeout, false)
            );
          })()}
      </Box>
    </>
  );
}

import React, { useEffect } from "react";
import Categories from "../pages/categories/Categories";

const Display = () => {

    useEffect(() => {
      localStorage.setItem('display', true)
    }, [])
    return (
        <Categories />
    )
}

export default Display
import React, { useEffect } from "react";
import Categories from "../pages/categories/Categories";

const Client = () => {

    useEffect(() => {
      localStorage.setItem('display', false)
    }, [])
    return (
        <Categories />
    )
}

export default Client
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"

const PopupPDF = ({show, setShow, url}) => {
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img alt="" style={{ width: "40%" }} src="/assets/hb-logo.png" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "fit-content" }} >
            <iframe src={`/pdfs/${url}`} height={1000} width={"100%"} />
        </Modal.Body>
      </Modal>
    )
}

export default PopupPDF
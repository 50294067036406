import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../..";

const Service = (props) => {
  const service = props.service;
  const [lang] = useGlobalState("lang");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/services/details/${service._id}`);
  };

  return (
    <Box className="post-card-item grid-posts-container" key={service._id}>
      <Box className="post-img-box">
        
        <img
          className="post-img"
          src={service.thumbs[0]}
          alt="Post thumbnail"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <Box padding="0px 15px" className="post-content" onClick={handleClick}>
        <div className="header-wrapper-service">
          <h3
            className="post-title"
            style={{
              margin: "10px 0px 0px 0px",
              fontFamily: "Franklin Gothic Medium Regular, sans-serif",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            {service.title[lang]}
          </h3>
          <img className="post-logo" alt="" src={service.logo} />
        </div>
        <Stack
          paddingBottom="10px"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          style={{
            marginTop: "2vh"
          }}
        >
          <p
            className="post-cat"
            style={{
              fontFamily: "Franklin Gothic Book Regular, sans-serif",
              fontWeight: 600,
              fontSize: "18px",
              textTransform: "uppercase",
              color: "#B7B7B7",
            }}
          >
            {service.category.title[lang]}
          </p>
          <a
            style={{ padding: "8px 16px", borderRadius: "5px" }}
            className="button"
            onClick={handleClick}
          >
            Détails
          </a>
        </Stack>
      </Box>
    </Box>
  );
};

export default Service;

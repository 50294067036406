import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";
import ConfirmSuccess from "./confirm-success/ConfirmSuccess";
import Confirmation from "./confirmation/Confirmation";
import Reservation from "./reservation/Reservation";

const ReservationProcess = () => {
    const [step, setStep] = useGlobalState("step")
    const { id } = useParams()
    const [data, setData] = useState({
        date: "",
        service_id: id,
        people_count: 1,
        room_number: "",
        ext: "+212",
        phone: "",
        first_name: "",
        last_name: "",
        email: ""
    })

    // useEffect(() => {
    //   console.log(data)
    // }, [data])

    useEffect(() => {
        if (step === 2){
            AppServices.post(data, '/email')
        }
    }, [step])


    
    const steps = [
        <Reservation setData={setData} data={data}/>,
        <Confirmation setData={setData} data={data}/>,
        <ConfirmSuccess setData={setData} data={data}/>
    ]
    return (
        steps[step]
    )
}

export default ReservationProcess
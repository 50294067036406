import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../..";
import ArrowBack from "../../assets/drawable/left-arrow.svg";
import AppServices from "../../services/AppServices";
import "./ServicePage.css";
import { htmlToText } from "html-to-text";
import textversionjs from "textversionjs";
import NavBar from "../../components/NavBar";
import PopupPDF from "../../components/PopupPDF";

const ServicePage = () => {
  const { service_id } = useParams();
  const navigate = useNavigate();
  const [lang] = useGlobalState("lang");
  const [url, setURL] = useGlobalState("url")
  const [loader, setLoader] = useGlobalState("loader");
  const [details, setDetails] = useState({
    _id: "",
    active: true,
    category: {
      _id: "",
      active: true,
      created_on: "2022-11-16 22:42:46.415000",
      image: "",
      title: {
        en: "....",
        fr: ".....",
      },
    },
    created_on: "2022-11-16 22:42:46.415000",
    description: {
      en: ".....",
      fr: ".....",
    },
    file: "#",
    file_text: {
      en: "....",
      fr: "....",
    },
    reservation_link: "....",
    subtitle: {
      en: ".....",
      fr: ".....",
    },
    thumbs: ["#"],
    title: {
      en: "....",
      fr: "....",
    },
    video_link: false
  });
  const [show, setShow] = useState(false)


  useEffect(() => {
    document.getElementById("description").innerHTML = details.description[lang].replaceAll("<p></p>", "<br>")
    
  }, [details.description, lang])
  
  const handleFile = () => {
    if (details.file.includes('http') || localStorage.getItem('display') === 'false' || !localStorage.getItem('display')){
      if (details.file.includes('http')){
        window.open(details.file, '_blank')
      }else{
        window.open(`/pdfs/${details.file}`, '_blank')
      }
      
    }else{
      setShow(true)
    }
  }

  useEffect(() => {
    setLoader(true);
    AppServices.post(
      {
        action: 5,
        data: {
          id: service_id,
        },
      },
      "/get"
    ).then((response) => {
      setDetails(response.data[0]);
      setLoader(false);
    });
  }, [service_id, setLoader]);

  const handleYoutube = (video) => {
    if (video.includes("watch")) {
      return `https://www.youtube.com/embed/${video.split("v=", 2)[1].split("&")[0]}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${video.split("v=", 2)[1].split("&")[0]}&controls=0`
    } else {
      return `https://www.youtube.com/embed/${video.split("be/", 2)[1]}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${video.split("be/", 2)[1]}&controls=1`
    }
  
  }

  useEffect(() => {
    AppServices.post({
      action: 0,
      data: {
         user_action: 0,
         from_client: localStorage.getItem('display') === "false" || !localStorage.getItem('display') ? true : false,
         token: "-1",
         service: service_id
     }
   }, '/track')
  }, [])
  

  return (
    <>
      <NavBar />
      <PopupPDF show={show} setShow={setShow} url={details.file}/>
      <Box className="single-post-wrapper">
        <Box className="" sx={{ padding: { xs: "0px", md: "0px 300px" } }}>
          <div className="featured-img-box">
            <Carousel touch className="featured-img" interval={details.video_link ? null : 1500} controls={details.video_link || details.thumbs.length > 1}>
              {details.video_link ? <Carousel.Item width="100%" height="100%">
                <object
                  id="player"
                  type="text/html"
                  width="100%"
                  height="330"
                  allow="autoplay"
                  data={details.video_link ? handleYoutube(details.video_link) : null}
                  frameborder="0"
                ></object>
              </Carousel.Item> : null}
              
              {details.thumbs.map((thumb, i) => {
                return (
                  <Carousel.Item width="100%" height="100%" key={i}>
                    <img
                      style={{ height: "320px" }}
                      className="d-block w-100"
                      src={thumb}
                      alt="First slide"
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>

            {/*   <img
            
            src={details.thumbs[0]}
            alt="post thumbnail"
          />*/}
            <button
              className="goBackBut float"
              onClick={() => navigate(`/services/${details.category._id}`)}
            >
              <img src={ArrowBack} alt="" />
            </button>
          </div>
        </Box>
        <Box
          sx={{
            padding: { xs: "20px", md: "20px 300px 0px 300px" },
          }}
        >
          <div className="title-details">
            <div className="title-container">
              <h1 style={{ fontWeight: "700" }}>{details.title[lang]}</h1>
              <p className="single-post-cat">{details.category.title[lang]}</p>
            </div>
          </div>
          <div className="reserve-container-1">

            {details.file ? <a
              className="btn-reserv"
              onClick={handleFile}
              style={{
                backgroundColor: "#E7E7E7",
                color: "#000000",
                display: "flex",
                flexDirection: "row",
                gap: "13px",
                alignItems: "center"
              }}
            >
              <img
                alt=""
                src="https://cdn-icons-png.flaticon.com/512/724/724933.png"
                style={{ width: "20px", height: "20px" }}
              />
              <p className="reservationText bold">{details.file_text[lang]}</p>
            </a>: null}

            <p className="res-time" style={{ display: "flex", gap: "10px" }}>
              {details.sub_title_logo ? <img
                alt=""
                src={details.sub_title_logo}
                style={{ width: "50px" }}
              /> : null}
              <p className="reservationText">{details.subtitle[lang]}</p>
            </p>
            <p id="description"></p>

            {details.reservation !== "" ? <a
              className="button reserve"
              onClick={ () => {
                AppServices.post({
                   action: 0,
                   data: {
                      user_action: 1,
                      from_client: localStorage.getItem('display') === "false" || !localStorage.getItem('display') ? true : false,
                      service: service_id,
                      token: "-1"
                  }
                }, '/track')
                if (details.use_redirection){
                  window.open(details.reservation)
                }
                else if(!details.use_reservation_email){
                  setURL(details.reservation);
                  navigate(`/iframe`);
       
                  
                }else{
                  localStorage.setItem("service_name", details.title[lang])
                  navigate(`/reservation/${details._id}`)
                }
                }}
            >
              Réserver
            </a>: null}
          </div>
        </Box>

        {localStorage.getItem("display") === "true" &&
          (function () {
            const idleDurationSecs = 30; // X number of seconds
            const redirectUrl = "/"; // Redirect idle users to this URL
            let idleTimeout; // variable to hold the timeout, do not modify

            const resetIdleTimeout = function () {
              // Clears the existing timeout
              if (idleTimeout) clearTimeout(idleTimeout);

              // Set a new idle timeout to load the redirectUrl after idleDurationSecs
              idleTimeout = setTimeout(
                () => (window.location.href = redirectUrl),
                idleDurationSecs * 1000
              );
            };

            // Init on page load
            resetIdleTimeout();

            // Reset the idle timeout on any of the events listed below
            ["click", "touchstart", "mousemove"].forEach((evt) =>
              document.addEventListener(evt, resetIdleTimeout, false)
            );
          })()}
      </Box>
    </>
  );
};

export default ServicePage;
